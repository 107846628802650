<template>
  <div class="designs-container">
    <table border="0">
      <thead>
        <tr>
          <th>id</th>
          <th>created</th>
          <th>text1</th>
          <th>wood type</th>
          <th>ip address</th>
        </tr>
      </thead>
      <tbody>
        <tr :key="design._id" v-for="design in $store.getters['admin/designs']">
          <td>{{ design._id.substring(0, 5) }}</td>
          <td>{{ design.created }}</td>
          <td>{{ design.text1 }}</td>
          <td>{{ design.wood_type }}</td>
          <td>{{ design.ip_address }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'DesignsAdminDesktop',
};
</script>

<style lang="scss" scoped>
.designs-container {
  table {
    background-color: white;
    color: $tuatara;
    width: 100%;
    border-spacing: 0;
    text-align: left;
    padding: 10px;

    td {
      padding: 5px 0;
    }

    th {
      text-align: center;
    }

    tr:nth-child(even) {
      background-color: lightblue;
    }
  }
}
</style>
